import { z } from 'zod';

const errorMsg = 'Поле не может быть пустым';

// Общая схема для обязательных полей
export const requiredFieldSchema = z.string().min(1, errorMsg);

// Общая схема для необязательных полей
export const optionalFieldSchema = z.string().optional();

export const specializationSchema = z.object({
  title: requiredFieldSchema,
  slug: requiredFieldSchema,
  seoTitle: optionalFieldSchema,
  h1: requiredFieldSchema,
});
